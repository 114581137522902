<template>
  <div>
    <div class="container" style="margin-bottom: 1rem">
      <el-row>
        <content-title :nav="nav"></content-title>
      </el-row>
      <el-descriptions title="场馆详情" v-if="orgInfo.id">
        <el-descriptions-item label="场馆名称">
          {{ orgInfo.name }}
        </el-descriptions-item>
        <el-descriptions-item label="场馆地址">
          {{ orgInfo.address }}
        </el-descriptions-item>
        <el-descriptions-item label="所属机构">
          <link-to
            :to="{ name: 'mecDetails', query: { mecID: orgInfo.mechanism_id } }"
          >
            {{ orgInfo.mechanismName }}</link-to
          >
        </el-descriptions-item>
        <el-descriptions-item label="经营类目">
          {{ orgInfo.categories_child }}
        </el-descriptions-item>
        <el-descriptions-item label="状态">
          <span v-if="orgInfo.status == 0" style="color: #32cd32">正常</span>
          <span v-if="orgInfo.status == 1" style="color: #ff6262">已冻结</span>
        </el-descriptions-item>
        <el-descriptions-item label="类型">
          <div>
            {{ getLabel("typeList", orgInfo.type)
            }}<span v-if="orgInfo.sharing != 'no'"
              >~{{ getLabel("shareList", orgInfo.sharing) }}</span
            >
          </div>
        </el-descriptions-item>

        <el-descriptions-item label="场馆介绍">
          {{ orgInfo.introduction_content }}
        </el-descriptions-item>

        <el-descriptions-item label="封面">
          <el-image
            style="width: 150px; height: 150px"
            :src="orgInfo.images"
            fit="cover"
            :preview-src-list="[orgInfo.images]"
          ></el-image>
        </el-descriptions-item>

        <el-descriptions-item label="场馆图片">
          <el-image
            v-for="(pic, index) in orgInfo.introduction_pic.split(',')"
            style="width: 150px; height: 150px"
            :src="pic"
            fit="cover"
            :preview-src-list="orgInfo.introduction_pic.split(',')"
            :key="index"
          ></el-image>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="container">
      <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-tab-pane label="服务" name="serve">
          <el-table :data="couList" v-loading="is_loading">
            <el-table-column label="服务名称" prop="name"> </el-table-column>
            <el-table-column label="类目" prop="categories"> </el-table-column>
            <el-table-column label="服务次数" prop="num"></el-table-column>
            <el-table-column
              label="时长/小时"
              prop="duration"
            ></el-table-column>
            <el-table-column label="购买数" prop="sold_out"></el-table-column>
            <el-table-column
              label="所属场馆"
              prop="venueName"
            ></el-table-column>
            <el-table-column label="是否优选" prop="is_recommend">
              <template slot-scope="scope">
                {{ scope.row.is_recommend ? "优选" : "否" }}
              </template>
            </el-table-column>
            <el-table-column label="状态" prop="status">
              <template slot-scope="scope">
                <el-tag type="info" v-if="scope.row.status == 0">待审核</el-tag>
                <el-tag type="success" v-if="scope.row.status == 1"
                  >上架中</el-tag
                >
                <el-tag type="warning" v-if="scope.row.status == 2"
                  >冻结</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="" width="220">
              <template slot-scope="scope">
                <div class="flex flex-wrap">
                  <el-button
                    size="small"
                    type="primary"
                    @click="toServeDetails(scope.row)"
                    >详情</el-button
                  >
                  <el-button
                    size="small"
                    type="danger"
                    v-if="scope.row.status == 1"
                    @click="frozen(scope.row, 2)"
                    >冻结
                  </el-button>
                  <el-button
                    size="small"
                    type="success"
                    v-if="scope.row.status != 1"
                    @click="frozen(scope.row, 1)"
                    >上架
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="changeCouListCurrentPage"
            :current-page="couListCurrentPage"
            :total="couTotal"
            background
          ></el-pagination>
        </el-tab-pane>
        <!-- 订单列表 -->
        <el-tab-pane label="订单" name="order" style="padding: 1rem 1rem 0">
          <el-table
            :data="payList"
            :fit="true"
            :cell-style="{ 'text-align': 'center' }"
            :header-cell-style="{ 'text-align': 'center' }"
            v-loading="is_loading"
          >
            <el-table-column align="center" label="订单号" width="150">
              <template v-slot="{ row }">
                <p class="textell">{{ row.flowing_no }}</p>
              </template>
            </el-table-column>

            <el-table-column align="center" label="商品名称">
              <template v-slot="{ row }">
                <link-to
                  :to="{
                    name: 'serveDetails',
                    params: { id: row.studycard_id },
                  }"
                  >{{ OrderName(row) }}</link-to
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="course_num"
              align="center"
              label="数量"
            ></el-table-column>
            <el-table-column align="center" label="用户昵称">
              <template v-slot="{ row }">
                <link-to
                  :to="{ name: 'userDetails', params: { id: row.user_id } }"
                >
                  {{
                    row.map.userinfo ? row.map.userinfo.nick_name : ""
                  }}</link-to
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="amount"
              align="center"
              label="付款金额"
            ></el-table-column>
            <el-table-column
              prop="pay_type"
              align="center"
              label="付款方式"
              :formatter="Paytype"
            ></el-table-column>
            <el-table-column
              prop="source"
              align="center"
              label="订单渠道"
              :formatter="Paysource"
            ></el-table-column>
            <el-table-column
              prop="finished_time"
              align="center"
              label="下单时间"
              width="200px"
            ></el-table-column>
            <el-table-column prop="city" align="center" label="订单类型">
              <template slot-scope="{ row }">
                <div>{{ OrderType(row.rcharge_type) }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 2" style="color: #67c23a"
                  >已付款</span
                >
                <span v-if="scope.row.status == 4" style="color: #f56c6c"
                  >退款</span
                >
                <span v-if="scope.row.status == 1" style="color: #6f7174"
                  >待付款</span
                >
                <span v-if="scope.row.status == 0" style="color: #6f7174"
                  >待付款</span
                >
                <span v-if="scope.row.status == 8" style="color: #6f7174"
                  >已取消</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template v-slot="{ row }">
                <link-to :to="'orderDetails/' + row.id">
                  <p>详情</p>
                </link-to>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="payListCurrentPage"
            :total="payTotal"
            @current-change="changePayListCurrentPage"
            background
          ></el-pagination>
        </el-tab-pane>
        <el-tab-pane label="设施" name="device" style="padding: 1rem 1rem 0">
          <el-form :inline="true">
      <el-form-item label="设施">
          <el-input
            v-model="v_name"
            placeholder="设施名称"
          ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchDevice">搜索</el-button>
      </el-form-item>
    </el-form>
          <el-button @click="openAdd" type="primary">新增设施</el-button>
          <el-table :data="deviceList" v-loading="is_loading">
            <el-table-column type="index"></el-table-column>
            <el-table-column label="名称" prop="name"></el-table-column>
            <el-table-column label="容量" prop="max_people"></el-table-column>
            <el-table-column label="状态" prop="sex">
              <template slot-scope="{ row }">
                <el-tag v-if="row.status === 0">可使用</el-tag>
                <el-tag type="danger" v-if="row.status === 1">停止使用</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="">
              <template slot-scope="{ row }">
                <el-button type="primary" size="small" @click="openEdit(row)"
                  >修改</el-button
                >
                <el-button
                  type="warning"
                  size="small"
                  v-if="row.status === 0"
                  @click="handlerDevice(row, 1)"
                  >停止使用</el-button
                >
                <el-button
                  type="success"
                   size="small"
                  v-if="row.status === 1"
                  @click="handlerDevice(row, 0)"
                  >开放使用</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="deviceListCurrentPage"
            :total="devTotal"
            @current-change="changedeviceListCurrentPage"
            background
          ></el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>

 
    <addDevice ref="device" @success="getDeviceList"></addDevice>
  </div>
</template>
  
<script>
import { getorderType } from "@/utils/map/orderType.js";
import addDevice from './components/addDevice.vue';
export default {
 components:{
    addDevice
 },
  data() {
    return {
      id: "",
      nav: { firstNav: "机构管理中心", secondNav: "场馆详情" },
      orgInfo: {},
      is_loading: true,
      activeName: "serve",
      show: false,
      deviceName: "",
      mode: "add",
      curDev: {},
      couList: [],
      couTotal: 0,
      couListCurrentPage: 1,

      placeList: [],
      placeTotal: 0,
      placeListCurrentPage: 1,

      teaList: [],
      teaTotal: 0,
      teaListCurrentPage: 1,

      payList: [],
      payTotal: 0,
      payListCurrentPage: 1,

      deviceList: [],
      devTotal: 0,
      deviceListCurrentPage: 1,

      spyList: [],
      spyTotal: 0,
      spyListCurrentPage: 1,
      // unFinishedCourse:[],

      typeList: [
        {
          label: "机构场馆",
          value: "venue",
        },
        {
          label: "教练上课场地",
          value: "coach_venue",
        },
      ],
      shareList: [
        {
          label: "不共享",
          value: "no",
        },
        {
          label: "共享",
          value: "share",
        },
        {
          label: "社区专属",
          value: "community",
        },
      ],
      v_name:''
    };
  },
  methods: {
    getLabel(list, v) {
      for (let index = 0; index < this[list].length; index++) {
        if (this[list][index].value === v) {
          return this[list][index].label;
        }
      }
    },
    openAdd() {
       let comm =  { venue_id: this.orgInfo.id,
          mechanism_id: this.orgInfo.mechanism_id,share_categories:this.orgInfo.categories_child,busy_share_categories:this.orgInfo.categories_child}
     this.$refs.device.open(comm)
    },
    openEdit(row) {
         this.$refs.device.open(row)
    },
    
    // 更新设备
    handlerDevice(row, status) {
      let url = "user/venue/device/update";
      this.$axios
        .post(url, {
          id: row.id,
          status: status,
        })
        .then((res) => {
          this.show = false;
          this.getDeviceList();
        });
    },
    // 上架服务
    frozen(row, status) {
      let url = "/user/venue/server/update";
      this.$axios
        .post(url, {
          id: row.id,
          status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.getOrgCouList();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    toServeDetails(row) {
      this.$router.push({
        name: "serveDetails",
        params: {
          id: row.id,
        },
      });
    },
    OrderType(type) {
      return getorderType(type).label;
    },
    OrderName(row) {
      if (row.title) {
        return row.title;
      } else if (row.map.masterSetPriceEntity) {
        return row.map.masterSetPriceEntity.title;
      } else {
        return this.OrderType(row.rcharge_type);
      }
    },
    Paytype(row, column) {
      let type = row.pay_type;
      if (type == "ali") {
        return "支付宝";
      } else if (type == "wx") {
        return "微信";
      } else if (type == "point") {
        return "权益点";
      } else if (type == "dy") {
        return "抖音";
      } else {
        return type;
      }
    },
    Paysource(row) {
      let source = row.source;
      let pay = row.pay_type;
      if (source == "sport" && pay == "dy") {
        return "抖音小程序";
      } else if (source == "sport" && pay != "dy") {
        return "微信小程序";
      } else {
        return source;
      }
    },
    splitImgs(urlString) {
      return urlString ? urlString.split(",") : [];
    },
    getOrgPayList() {
      if (!this.orgInfo.mechanism_id) {
        this.is_loading = false;
        return;
      }
      let url = "/user/rechargerecord/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 10,
            currentPage: this.payListCurrentPage,
            rcharge_type: "venue",
            mechanism_id: this.orgInfo.mechanism_id,
          },
        })
        .then((res) => {
          this.payList = res.data.data.rows;
          this.payTotal = res.data.data.total;
          this.is_loading = false;
        });
    },
    getOrgDetails() {
      let url = "/user/mechanism/venue/findById";
      this.$axios
        .get(url, {
          params: {
            id: this.id,
          },
        })
        .then((res) => {
          this.orgInfo = res.data.data
        });
    },
    getOrgCouList() {
      let url = "/user/venue/server/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.couListCurrentPage,
            venue_id: this.id,
          },
        })
        .then((res) => {
          this.couList = res.data.data.rows;
          this.couTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    getOrgPlaceList() {
      let url = "/user/mechanism/venue/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.placeListCurrentPage,
            mechanism_id: this.id,
          },
        })
        .then((res) => {
          // console.log(res);
          this.placeList = res.data.data.rows;
          this.placeTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    getOrgTeaList() {
      let url = "/user/coachMechanismConnected/list";
      this.$axios
        .get(url, {
          params: {
            mechanism_parent_id: this.id,
            pageSize: 10,
            currentPage: this.teaListCurrentPage,
          },
        })
        .then((res) => {
          this.teaList = res.data.data.rows;
          this.teaTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },
    searchDevice(){
        this.deviceListCurrentPage = 1
        this.getDeviceList()
    },
    getDeviceList() {
      let url = "/user/venue/device/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            venue_id: this.id,
            pageSize: 10,
            currentPage: this.deviceListCurrentPage,
            name:this.v_name || null
          },
        })
        .then((res) => {
          this.deviceList = res.data.data.rows;
          this.devTotal = res.data.data.total;
          this.is_loading = false;
        })
        .catch((rej) => {
          this.is_loading = false;
        });
    },

    unshelve(id) {
      let url = "/user/masterInfo/update";
      this.$axios
        .post(url, {
          id: id,
          status: 1,
        })
        .then((res) => {
          this.is_loading = true;
          this.getOrgTeaList();
          this.$message({ type: "success", message: "下架成功!" });
        });
    },
    pass() {
      let url = "/user/mastermechanism/update";
      this.$axios
        .post(url, {
          id: this.orgInfo.id,
          status: 2,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "修改成功!" });
            this.getOrgDetails();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    changeRecommand(status) {
      let url = "/user/mastermechanism/update";
      this.$axios
        .post(url, {
          id: this.orgInfo.id,
          is_recommend: status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "修改成功!" });
            this.getOrgDetails();
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    toCouDetails(id) {
      this.$router.push({
        name: "courseDetails",
        params: { id: id },
      });
    },

    toPlaceDetails(id) {
      this.$router.push({
        name: "venueDetails",
        params: { id: id },
      });
    },
    toCoachDetails(row) {
      this.$router.push({ name: "coachDetails", query: { id: row.id } });
    },
    toStuDetails(id) {
      this.$router.push({
        name: "userDetails",
        params: { id },
      });
    },
    changeCouListCurrentPage(v) {
      this.is_loading = true;
      this.couListCurrentPage = v;
      this.getOrgCouList();
    },
    changePlaceListCurrentPage(v) {
      this.is_loading = true;
      this.placeListCurrentPage = v;
      this.getOrgPlaceList();
    },
    changeTeaListCurrentPage(v) {
      this.is_loading = true;
      this.teaListCurrentPage = v;
      this.getOrgTeaList();
    },
    changePayListCurrentPage(v) {
      this.is_loading = true;
      this.payListCurrentPage = v;
      this.getOrgPayList();
    },
    changedeviceListCurrentPage(v) {
      this.is_loading = true;
      this.deviceListCurrentPage = v;
      this.getDeviceList();
    },
    tabClick(tab) {
      this.is_loading = true;
      switch (tab.name) {
        case "serve":
          this.couListCurrentPage = 1;
          this.getOrgCouList();
          break;
        case "order":
          this.payListCurrentPage = 1;
          this.getOrgPayList();
          break;
        case "device":
          this.deviceListCurrentPage = 1;
          this.getDeviceList();
          break;
      }
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getOrgDetails();
    this.getOrgCouList();
  },
};
</script>
  
<style></style>
  